import React, { FunctionComponent } from 'react';
import { Form } from 'semantic-ui-react';
import './text.css';

interface Props {
  name: string;
  label?: any;
  inputProps: any;
  prepend?: any;
  append?: any;
  handleChange: (name: string) => void;
  handleBlur: (name: string) => void;
  value: any;
  error: any;
  touched: any;
  required?: boolean;
  icon?: any;
  helpText: string;
}

const TextAreaControl: FunctionComponent<Props> = (props: Props) => {
  const {
    name,
    label,
    icon,
    handleChange,
    handleBlur,
    value,
    error,
    touched,
    required,
    inputProps,
    helpText,
  } = props;
  return (
    <Form.Field required={required}>
      <Form.TextArea
        icon={icon}
        name={name}
        className="text-input-control"
        onChange={handleChange(name)}
        onBlur={handleBlur(name)}
        value={value}
        label={
          <>
            <label>{label}</label>
            {helpText && <p className="text-area-help-text">{helpText}</p>}
          </>
        }
        error={!!touched && error}
        {...inputProps}
      />
    </Form.Field>
  );
};

export default TextAreaControl;
