import React, { FunctionComponent } from 'react';
import '../assets/css/button.css';
import { Link } from 'react-scroll';

interface Props {
  icon: any;
  text: string;
  color: string;
  to?: string;
  borderColor: string;
}
const ButtonComponent: FunctionComponent<Props> = props => {
  const { icon, text, color, to, borderColor } = props;
  return (
    <div>
      {to === 'section2' ? (
        <Link
          className="button-wrapper"
          activeClass="active"
          to="section2"
          spy
          smooth
          offset={-42.5}
          duration={1250}
        >
          <button className="who-are-we-button">
            <div className="who-are-we-button-wrapper">
              <img className="who-are-we-button-img" src={icon} />
              <h1 className="who-are-we-button-text" style={{ color: color }}>
                {text}
              </h1>
            </div>
          </button>
        </Link>
      ) : (
        <a href={to as string} target="blank">
          <div className="button-wrapper">
            <button
              className="who-are-we-button"
              style={{ borderColor: borderColor }}
            >
              <img src={icon} />
              <div style={{ color: color }}>{text}</div>
            </button>
          </div>
        </a>
      )}
    </div>
  );
};

export default ButtonComponent;
