const img1 = require('../images/img1.svg');
const mobilImg = require('../logo/mobileMainImg.svg');
const androidLogo = require('../logo/androidLogo.svg');
const appleLogo = require('../logo/appleLogo.svg');
export default [
  {
    name: 'Kitap Dinler',
    description: 'Bizi düşündüren, güldüren ve ağlatan hikayeleri seviyoruz.',
    description2:
      'Hikayeleri seviyoruz çünkü bizi eğlendiriyor, eğitiyor ve bize ilham veriyorlar. En sevdiğiniz kitabın tadını istediğiniz zaman ve istediğiniz yerde dinleyerek çıkarabilirsiniz. Kitap Dinler tam da bunu yapmanıza olanak sağlar.',
    subTitle: 'Dinle. Düşün. Değiş',
    subTitle2: 'Şimdi İndir!',
    img: img1,
    mobilImg: mobilImg,
    buttonText: 'Apple Store',
    buttonText2: 'Google Play',
    buttonText3: 'Bilgi Al',
    androidLogo: androidLogo,
    appleLogo: appleLogo,
    backgroundColor: '255, 255, 255, 0.8',
  },
];
