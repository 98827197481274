import React, { FunctionComponent, useEffect, useState } from 'react';
import '../assets/css/mainScreen.css';
import { Link } from 'react-scroll';

interface Props {
  data: any;
}
const MainScreen: FunctionComponent<Props> = props => {
  const { data } = props;
  const [withState, setWithState] = useState(false);
  const pageWith = window.innerWidth;

  useEffect(() => {
    if (window.innerWidth > 1024) {
      setWithState(true);
    } else {
      setWithState(false);
    }
  }, [pageWith, withState]);
  return (
    <>
      <div className="slider-container-img">
        {data.diffPage === true ? (
          <>
            <div className="phone-image-container">
              <div className="text-container">
                <h1 className="title">{data.title}</h1>
                <div className="main-subtitle ">{data.subTitle}</div>
                <div className="main-subtitle2">{data.subTitle2}</div>
                <a
                  href="https://apps.apple.com/gb/app/kitapdinler/id1559060856"
                  target="blank"
                  className="download-button"
                >
                  <button
                    className="download-button"
                    style={
                      data.backgroundColor
                        ? { backgroundColor: `rgba(${data.backgroundColor})` }
                        : {
                            backgroundColor: 'transparent',
                            border: '1.4486px solid #FFFFFF',
                            color: '#ffffff',
                          }
                    }
                  >
                    <img src={data.appleLogo.default} className="icon" />
                    <h1 className="download-button-text">{data.buttonText}</h1>
                  </button>
                </a>
                <a
                  href="https://play.google.com/store/apps/details?id=com.kitapdinler.kitapdinler&gl=TR"
                  target="blank"
                  className="download-button"
                >
                  <button
                    className="download-button"
                    style={
                      data.backgroundColor
                        ? { backgroundColor: `rgba(${data.backgroundColor})` }
                        : {
                            backgroundColor: 'transparent',
                            border: '1.4486px solid #FFFFFF',
                            color: '#ffffff',
                          }
                    }
                  >
                    <img src={data.androidLogo.default} className="icon" />
                    <h1 className="download-button-text">{data.buttonText2}</h1>
                  </button>
                </a>
                <div className="text1">{data.text1}</div>
                <div className="text2">{data.text2}</div>
                <Link
                  activeClass="active"
                  to="section1"
                  spy
                  smooth
                  offset={-42.5}
                  duration={1250}
                  className="button-container"
                >
                  <button className="button"> {data.buttonText3}</button>
                </Link>
              </div>
            </div>
          </>
        ) : (
          <div className="slider-right-card-image">
            <img src={data.img.default} className="slider-right-image" />
            <div className="slider-right-text-container">
              <div className="slider-text-wrapper">
                <div className="main-title">{data.name}</div>
                <div className="main-subtitle left">{data.subTitle}</div>
                <div className="main-subtitle2">{data.subTitle2}</div>
                <a
                  href="https://apps.apple.com/gb/app/kitapdinler/id1559060856"
                  target="blank"
                  className="download-button"
                >
                  <button
                    className="download-button"
                    style={
                      data.backgroundColor
                        ? { backgroundColor: `rgba(${data.backgroundColor})` }
                        : {
                            backgroundColor: 'transparent',
                            border: '1.4486px solid #FFFFFF',
                            color: '#ffffff',
                          }
                    }
                  >
                    <img src={data.appleLogo.default} className="icon" />
                    {data.buttonText}
                  </button>
                </a>
                <a
                  href="https://play.google.com/store/apps/details?id=com.kitapdinler.kitapdinler&gl=TR"
                  target="blank"
                  className="download-button"
                >
                  <button
                    className="download-button"
                    style={
                      data.backgroundColor
                        ? { backgroundColor: `rgba(${data.backgroundColor})` }
                        : {
                            backgroundColor: 'transparent',
                            border: '1.4486px solid #FFFFFF',
                            color: '#ffffff',
                          }
                    }
                  >
                    <img src={data.androidLogo.default} className="icon" />
                    {data.buttonText2}
                  </button>
                </a>
                <div className="main-description">{data.description}</div>
                <div className="main-description2">{data.description2}</div>
                <div className="main-button-container">
                  <Link
                    activeClass="active"
                    to="section1"
                    spy
                    smooth
                    duration={1250}
                  >
                    <button className="button"> {data.buttonText3} </button>
                  </Link>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default MainScreen;
