import React, { ReactElement, useState } from 'react';
import MainScreen from '../common/MainScreen';
import firstData from '../assets/data/podcast-data';
import bannerData from '../assets/data/banner-data';
import fullScreenData from '../assets/data/podcastScreenData';
import BannerComponent from '../common/Banner';
import FooterComponent from '../common/Footer';
import FullScreenContainer from '../common/FullScreenContainer';
import ScrollButtonComponent from '../common/ScrolleButton';
import blackBannerData from '../assets/data/blackBannerData';
import BlackBanner from '../common/BlackBanner';

const Podcasts = (): ReactElement => {
  const [height, setHeight] = useState(false);
  window.onscroll = function(): void {
    if (window.scrollY > 500) {
      setHeight(true);
    } else {
      setHeight(false);
    }
  };
  return (
    <div className="App">
      <title>Kitap Dinler Podcasts</title>
      <meta
        property="og:description"
        data-rh="true"
        content="Kitap Dinler,Sesli Kitap, Kitap Dinle, Sesli Kitap İndir ,Dinle,"
      />
      <link href="/podcastler" rel="stylesheet"></link>
      <MainScreen data={firstData[0]} />
      <BlackBanner data={blackBannerData[0]} />
      <ScrollButtonComponent height={height} />
      <div
        style={{
          background:
            'linear-gradient( 180deg, #000208 8.65%, #6d4753 24.37%, #905a81 41.39%, #502d80 58.65%, #1c1b60 75.83%, #0b1552 92.5%)',
        }}
      >
        {fullScreenData.map((item, index) => (
          <FullScreenContainer
            data={fullScreenData[index]}
            key={index}
            color="white"
          />
        ))}
      </div>
      <BannerComponent data={bannerData[0]} />
      <FooterComponent />
    </div>
  );
};

export default Podcasts;
