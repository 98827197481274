const img1 = require('../images/bannerImg.svg');
const buttonIcon = require('../logo/apple.svg');
const buttonIcon2 = require('../logo/android.svg');

export default [
  {
    name: 'Şimdi İndir!',
    subTitle: 'Uygulamamızı Ücretsiz Edinmek İster misiniz? ',
    subTitle2:
      'Tüm Tablet ve Telefon Modellerine Uygun Olarak Geliştirilmiş Uygulamamızı Hemen Şimdi İndirip Kitapları ve Podcastleri Dinlemeye Başlayabilirsiniz. Hemen indir!',
    subTitle3: 'Hemen Dinle!',
    img: img1,
    buttonIcon: buttonIcon,
    buttonIcon2: buttonIcon2,
    section: 'section4',
    buttonText: 'Apple Store',
    buttonText2: 'Google Play',
  },
];
