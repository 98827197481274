import React, { FunctionComponent, useCallback } from 'react';
import { Form } from 'semantic-ui-react';
import './text.css';

export interface TextControlProps {
  name: string;
  label?: string;
  inputProps?: any;
  prepend?: any;
  append?: any;
  required?: boolean;
  icon?: any;
  formikProps: any;
  onChange?: (value: any) => void;
  value: any;
  className?: string;
  type?: any;
}

const TextInputControl: FunctionComponent<TextControlProps> = (
  props: TextControlProps
) => {
  const {
    name,
    label,
    icon,
    required,
    inputProps,
    formikProps,
    value,
    className,
    type,
  } = props;
  const { handleChange, handleBlur, errors, touched } = formikProps;
  const myError = errors && errors[name];
  const myTouched = touched && touched[name];
  const myHandleChange = useCallback(
    (e: any) => {
      handleChange(name)(e);
    },
    [handleChange, name]
  );
  return (
    <Form.Input
      required={required}
      icon={icon}
      autoComplete="off"
      name="name"
      className={className}
      onChange={myHandleChange}
      onBlur={handleBlur}
      value={value}
      error={!!myTouched && myError}
      placeholder={label}
      aria-invalid="true"
      style={{ with: '100%' }}
      type={type}
      {...inputProps}
      fluid
    />
  );
};

export default TextInputControl;
