import React, { FunctionComponent, useEffect, useState } from 'react';
import { Link } from 'react-scroll';
import '../assets/css/HamburgerMenu.css';
import logo from '../assets/logo/logo.svg';
import hmbrglogo from '../assets/logo/hmbrgIcon.svg';
import closeIcon from '../assets/logo/closeIcon.png';
import { useHistory } from 'react-router-dom';

const HamburgerMenu: FunctionComponent = () => {
  const [state, setState] = useState(false);
  const [open, setOpen] = useState(false);
  const history = useHistory();
  /**/
  const handleRouter = (name: string) => {
    setOpen(false);
    if (name === 'about') {
      history.push({
        pathname: '/',
        state: 'about',
      });
    }
    if (name === 'contact') {
      history.push({
        pathname: '/',
        state: 'contact',
      });
    }
  };
  useEffect(() => {
    if (window.location.pathname === '/') {
      setState(true);
      history.push({
        pathname: '/',
        state: false,
      });
    }
  }, []);

  return (
    <div className="hmbrg-menu-container">
      <img
        src={hmbrglogo}
        className="hmbrg-menu-logo"
        onClick={() => setOpen(!open)}
      />
      <a href="/">
        <img src={logo} className="hmbrg-logo" />
      </a>
      {open ? (
        <div className="hmbrg-menu-modal">
          <img
            src={closeIcon}
            className="hmbrg-menu-close-logo"
            onClick={() => setOpen(!open)}
          />
          <div>
            <div className="hmbrg-menu-items">
              <div>
                {window.location.pathname === '/' ? (
                  <Link
                    activeClass="active"
                    to="section2"
                    spy
                    smooth
                    offset={-42.5}
                    duration={1250}
                    className="hmbrg-menu-item"
                    onClick={() => setOpen(false)}
                  >
                    İletişim
                  </Link>
                ) : (
                  <a
                    className="hmbrg-menu-item"
                    onClick={() => handleRouter('contact')}
                  >
                    İletişim
                  </a>
                )}
              </div>
              <div>
                <a className="hmbrg-menu-item" href="/podcastler">
                  Podcastler
                </a>
              </div>
              <div>
                <a className="hmbrg-menu-item" href="/authors">
                  Yazarlarımız
                </a>
              </div>
              <div>
                <a className="hmbrg-menu-item" href="/booklist">
                  Kitaplar Listesi
                </a>
              </div>
              <div>
                {window.location.pathname === '/' ? (
                  <Link
                    activeClass="active"
                    to="section3"
                    spy
                    smooth
                    offset={-42.5}
                    duration={1250}
                    className="hmbrg-menu-item"
                    onClick={() => setOpen(false)}
                  >
                    Biz Kimiz?
                  </Link>
                ) : (
                  <a
                    className="hmbrg-menu-item"
                    onClick={() => handleRouter('about')}
                    id="gopage"
                  >
                    Biz Kimiz?
                  </a>
                )}
              </div>
            </div>
          </div>
        </div>
      ) : null}
    </div>
  );
};

export default HamburgerMenu;
