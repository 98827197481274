const vector1 = require('../vector/Category.svg');
const vector2 = require('../vector/Union.svg');
const vector3 = require('../vector/Vector1.svg');
const vector4 = require('../vector/Vector2.svg');
const vector5 = require('../vector/Vector3.svg');
const vector6 = require('../vector/Vector4.svg');

const image1 = require('../images/bookImg1.svg');
const image2 = require('../images/bookImg2.svg');
const image3 = require('../images/bookImg3.svg');
const image4 = require('../images/bookImg4.svg');
const image5 = require('../images/bookImg5.svg');

export default [
  {
    name: 'EN ÇOK DİNLENENLER',
    // subTitle: 'Uygulama ekran görüntüleriyle kullanım tanıtımı bölümü.',
    description: 'Bizi düşündüren, güldüren ve ağlatan hikayeleri seviyoruz.',
    description2:
      'Hikayeleri seviyoruz çünkü bizi eğlendiriyor, eğitiyor ve bize ilham veriyorlar.',
    description3: 'En çok dinlenen kitaplarımıza göz atmak İstermisiniz?',
    img: image1,
    imgPosition: 'left',
    section: 'section1',
    vector: vector5,
  },
  {
    name: 'DÜNYA KLASİKLERİ',
    description:
      'Dünya Klasikleri, öyle kitaplardır ki, onları okumuş ve sevmiş olanlar için alabildiğine değerli bir deneyim oluştururlar.',
    description2:
      'Uygulamamızda bulunan Dünya Klasiklerine hemen şimdi göz atabilirsiniz.',
    img: image2,
    imgPosition: 'right',
    vector: vector1,
  },
  {
    name: 'BİYOGRAFİ',
    description:
      'Biyografi kitapları bizlere, genel olarak, genelkültüre dair pek çok şey öğretir.',
    description2:
      'Özellikle biyografi okumak hayatı başkasının gözünden görmemizi de sağlar.',
    description3:
      'Bu nedenle de onların birinci elden deneyimlediği her şeyi öğreniriz. Uygulamamızda bulunan Biyografi kitaplarımıza hemen şimdi göz atabilirsiniz.',
    img: image3,
    imgPosition: 'left',
    vector: vector4,
  },
  {
    name: 'KİŞİSEL GELİŞİM',
    description:
      'Kişisel gelişim kitapları bir başkasının deneyimini yansıtırlar. Kişisel gelişim kitaplarının altın kelimesi kesinlikle deneyimdir.',
    description2:
      'Deneyimlemiş ve başarmış insanların yazdığı bu kitaplar size hem ilham olur hem de yöntemler geliştirmenizi sağlar.',
    description3:
      'Uygulamamızda bulunan Kişisel Gelişimkitaplarımıza hemen şimdi göz atabilirsiniz.',
    img: image4,
    imgPosition: 'right',
    vector: vector3,
  },
  {
    name: 'ARAŞTIRMA YAZILARI',
    description:
      'Araştırma Yazıları, yalnızca istisnai bir öğrenme fırsatı sağlamak için değil, aynı zamanda bu alanda daha fazla ilerlemenin önünü açmak için yazılır.',
    description2:
      'Bu yazılar, diğer bilim insanlarının yeni bir fikir dünyasına ya da uzun süredir devam eden bir sorunu çözmek için yenilikçi bir yönteme yol açabilecek düşünce tohumunu filizlendirmesine yardımcı olur.',
    description3:
      'Uygulamamızda bulunan `Araştırma Yazıları` konulukitaplarımıza hemen şimdi göz atabilirsiniz.',
    img: image5,
    imgPosition: 'left',
    vector: vector2,
  },
];
