import React, { FunctionComponent, useMemo, useState } from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import '../../assets/css/form.css';
import image from '../../assets/images/formImg.svg';
import buttonIcon from '../../assets/logo/mailIcon.svg';
import ButtonComponent from '../Button';
import TextInputControl from './text-input-control';
import { Form } from 'semantic-ui-react';
import TextAreaControl from './text-area-control';
import sendFunc from './SendFunc';

const FormComponent: FunctionComponent = () => {
  const [notifMsg, setNotifMsg] = useState('');

  const initialValues = useMemo(() => {
    return {
      name: '',
      email: '',
      description: '',
    };
  }, []);
  const schema = useMemo(
    () =>
      Yup.object().shape({
        name: Yup.string().required('Bu alan boş bırakılamaz.'),
        email: Yup.string()
          .email('Lütfen geçerli bir e-posta adresi giriniz')
          .required('Bu alan boş bırakılamaz.'),
        description: Yup.string().required('Bu alan boş bırakılamaz.'),
      }),
    []
  );
  const formik = useFormik({
    initialValues,
    onSubmit: (values, { resetForm }) => {
      const message =
        'Isim : ' +
        values.name +
        '\nEmail : ' +
        values.email +
        '\nMesaj : ' +
        values.description;
      sendFunc(values.email, message, setNotifMsg);
    },

    validationSchema: schema,
  });
  return (
    <div className="form-container" id="section2">
      <div className="form-card-image">
        <img src={image} className="form-image" />
        <div className="form-text-container">
          <div className="form-text-wrapper">
            <Form onSubmit={formik.handleSubmit} autoComplete="off">
              <div className="form-title">İletişim</div>
              <div className="form-subtitle">
                Size nasıl yardımcı olabiliriz? Lütfen bize yazın.
              </div>
              <div className="form-description">İletişim Formu</div>
              <div className="form-input">
                <div className="form-text">İsim</div>
                <TextInputControl
                  formikProps={formik}
                  name="name"
                  key="name"
                  value={formik.values.name}
                />
              </div>
              <div className="form-input">
                <div className="form-text">Email</div>
                <TextInputControl
                  formikProps={formik}
                  name="email"
                  key="email"
                  value={formik.values.email}
                />
              </div>
              <div className="form-input">
                <div className="form-text-area">Mesaj</div>
                <TextAreaControl
                  name="description"
                  inputProps={{
                    placeholder: '',
                  }}
                  handleChange={formik.handleChange}
                  handleBlur={formik.handleBlur}
                  value={formik.values.description}
                  error={formik.errors.description}
                  touched={formik.touched.description}
                  helpText=""
                />
              </div>
              <div className="form-button">
                <ButtonComponent
                  text="Bize Ulaşın"
                  icon={buttonIcon}
                  color="black"
                  to=""
                  borderColor="#3D3D3D"
                />
              </div>
            </Form>
            <div className="form-success-message">{notifMsg}</div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FormComponent;
