import React, { FunctionComponent } from 'react';
import '../assets/css/Banner.css';
import '../assets/css/FullScreenContainer.css';

import ButtonComponent from './Button';

interface Props {
  data: any;
}
const BannerComponent: FunctionComponent<Props> = props => {
  const { data } = props;
  return (
    <div
      className="banner-container"
      id={data.section}
      style={{ backgroundImage: `url(${data.img.default})` }}
    >
      <div className="banner-text-wrapper">
        <div className="banner-title">{data.name}</div>
        <div className="banner-subtitle">{data.subTitle}</div>
        <div className="banner-subtitle2">{data.subTitle2}</div>
        <div className="banner-subtitle2">{data.subTitle3}</div>
        <ButtonComponent
          icon={data.buttonIcon.default}
          text={data.buttonText}
          color="white"
          borderColor="white"
          to="https://apps.apple.com/gb/app/kitapdinler/id1559060856"
        />
        <ButtonComponent
          icon={data.buttonIcon2.default}
          text={data.buttonText2}
          color="white"
          borderColor="white"
          to="https://play.google.com/store/apps/details?id=com.kitapdinler.kitapdinler&gl=TR"
        />
      </div>
      {/* <div className="banner-image-container">
        <img className="banner-image" src={data.img.default} />
      </div> */}
    </div>
  );
};

export default BannerComponent;
