import React, { FunctionComponent, useState } from 'react';
import { Modal, Pagination } from 'semantic-ui-react';

import arrow from '../../assets/images/arrow.png';
import line from '../../assets/images/line.png';
interface Props {
  data: any;
  authors: boolean;
}
const Books: FunctionComponent<Props> = props => {
  const { data, authors } = props;
  const [open, setOpen] = React.useState(false);

  const pageCount = Math.ceil(data?.length / 15);
  const [currentPage, setCurrentPage] = useState<any>(1);

  const [bookDetail, setbookDetail] = useState<any>();

  const indexOfLastPost = currentPage * 15;
  const indexOfFirstPOST = indexOfLastPost - 15;
  const pureData = data?.filter((i: { hidden: any }) => !i.hidden);
  const currentPost = pureData?.slice(indexOfFirstPOST, indexOfLastPost);
  const modaldata = (bookmodaldetail: any): void => {
    setbookDetail(bookmodaldetail);
    setOpen(true);
  };
  return (
    <>
      <div className="book-container">
        <div className="book-wrapper">
          {currentPost?.map((item: any, index: number) => {
            return (
              <div key={index}>
                <div className={authors ? 'books-list author' : 'books-list'}>
                  <div className="books-list-wrapper">
                    <img
                      src={item?.coverPhoto?.images?.edges[0]?.node?.file?.url}
                      alt="logo"
                      className="books-img"
                      // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
                      onClick={() => modaldata(item)}
                    />
                    <div className="books-info">
                      <div
                        className={authors ? 'books-info author' : 'books-info'}
                        // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
                        onClick={() => setOpen(true)}
                      >
                        {item?.name}
                      </div>
                      <div
                        className={
                          authors ? 'books-info author' : 'books-info bookname'
                        }
                      >
                        {item?.authors?.edges[0]?.node?.name}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      </div>

      <Modal
        // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
        onClose={() => setOpen(false)}
        // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
        onOpen={() => setOpen(true)}
        open={open}
      >
        <Modal.Content>
          <div className="modals-container">
            <img
              src={arrow}
              alt="logo"
              className="modals-backicon"
              // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
              onClick={() => setOpen(false)}
            />
            <div className="modals-position">
              <div className="modals-wrapper">
                <div className="models-center top">
                  <img
                    src={
                      bookDetail?.coverPhoto?.images?.edges[0]?.node?.file?.url
                    }
                    alt="logo"
                    className="modals-img"
                  />
                  <div className="modals-text-container">
                    <Modal.Description>
                      <h1>{bookDetail?.name}</h1>
                      <p className="models-text">
                        {bookDetail?.authors?.edges[0]?.node?.name}
                      </p>
                      <p className="models-text-duration">
                        Süre: {bookDetail?.duration}
                      </p>
                      <p className="models-text-downloadCount">
                        {bookDetail?.downloadCount} kişi indirdi
                      </p>
                    </Modal.Description>
                  </div>
                </div>
                <img src={line} alt="logo" className="modals-line" />

                <div className="models-center bottom">
                  <div className="modals-text-container2">
                    <Modal.Description scrolling>
                      <h1>Kitap Hakkında</h1>
                      <p className="modals-info-text">
                        {bookDetail?.description}
                      </p>
                    </Modal.Description>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Modal.Content>
      </Modal>

      <div
        className={
          authors ? 'pagenation-container' : 'pagenation-container author'
        }
      >
        <div className="pagenation-wrapper">
          {authors ? null : (
            <Pagination
              ellipsisItem={null}
              firstItem={null}
              lastItem={null}
              defaultActivePage={1}
              totalPages={pageCount}
              // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
              onPageChange={(event, allBooksArray) =>
                setCurrentPage(allBooksArray.activePage)
              }
            />
          )}
        </div>
      </div>
      {open ? <div className="modal-mobile-bg"></div> : null}
    </>
  );
};

export default Books;
