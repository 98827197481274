import React, { FunctionComponent } from 'react';
import '../assets/css/whoAreWe.css';
import ButtonComponent from './Button';

interface Props {
  data: any;
  color: string;
}
const WhoAreWeComponent: FunctionComponent<Props> = props => {
  const { data, color } = props;

  return (
    <div className="waw-screen-container" id={data.section}>
      <div className="waw-screen-card-image">
        <div className="waw-screen-image">
          <img className="waw-screen-img" src={data.img.default} />
        </div>
        <div className="waw-screen-text-wrapper" style={{ color: color }}>
          <div className="waw-screen-text">
            <div className="waw-title">{data.name}</div>
            <div className="waw-description">{data.subTitle}</div>
            <div className="waw-description">{data.description}</div>
            <div className="waw-description">{data.subTitle2}</div>
            <div className="waw-description">{data.description2}</div>
            <div className="waw-description">{data.description3}</div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default WhoAreWeComponent;
