const bizkimiz = require('../images/bizkimiz.svg');
const buttonIcon = require('../logo/mailIcon.svg');
export default [
  {
    name: 'Biz Kimiz?',
    subTitle:
      'Bizler 2014 yılında Outreach to the Least Reached adında kurulmuş bir sivil toplum kuruluşuyuz. Misyonumuz ticari olmayan ve herhangi kar amacı gütmeyen projeler sunmaktır. KitapDinler sesli kitap ve podcast uygulaması bu projelerden biridir. ',
    description:
      'KitapDinler uygulamasının amacı ne zaman ve nerede olursanız olun yeni bir kitaba veya podcast`e başlamaya hazır olduğunuzda aralarından seçim yapabileceğiniz geniş perspektifli en iyi ve en yeni başlıkları size sunabilmektir.Bizi bir deneyin ve bırakın kitaplar ve podcastler sizi yeni bir dünya ile tanıştırsın.',
    description2:
      'Basitçe söylemek gerekirse uygulamamızda bulunan kitaplarımızı ve podcastlerimizi dünyada birçok noktaya ücretsiz olarak ulaştırmayı amaçlıyoruz. Bu proje tamamen gönüllüler tarafından desteklenmektedir.',
    description3: '',
    img: bizkimiz,
    imgPosition: 'left',
    buttonIcon: buttonIcon,
    buttonText: 'Bize Ulaşın',
    section: 'section3',
  },
];
