async function sendFunc(
  from_email: any,
  message: any,
  setNotifMsg: any
): Promise<any> {
  const to_email = 'admin@kitapdinler.com';
  return fetch('https://puente-mail-service-7wy28.ondigitalocean.app/mail/', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      to_email: to_email,
      from_email: from_email,
      message: message,
    }),
  }).then((response: any) => {
    // console.log("Response : ", response);
    if (response.status === 200) {
      setNotifMsg(
        'Mesajınız gönderildi! En kısa zamanda size dönüş sağlayacağız'
      );
    } else {
      setNotifMsg('Error: Mesajınız gönderilemedi');
    }
    return response;
  });
}

export default sendFunc;
