import React, { FunctionComponent, useEffect, useRef, useState } from 'react';
import { Link } from 'react-scroll';
import '../assets/css/routing-provider.styles.css';
import logo from '../assets/logo/logo.svg';
import { useHistory } from 'react-router-dom';
import HamburgerMenu from './HamburgerMenu';

const Navbar: FunctionComponent = () => {
  const [state, setState] = useState(false);
  const history = useHistory();
  const [withState, setWithState] = useState(false);
  const [screenSize, getDimension] = useState({
    dynamicWidth: window.innerWidth,
    dynamicHeight: window.innerHeight,
  });
  const setDimension = () => {
    getDimension({
      dynamicWidth: window.innerWidth,
      dynamicHeight: window.innerHeight,
    });
  };
  useEffect(() => {
    window.addEventListener('resize', setDimension);

    return () => {
      window.removeEventListener('resize', setDimension);
    };
  }, [screenSize]);

  useEffect(() => {
    if (screenSize.dynamicWidth > 1024) {
      setWithState(true);
    } else {
      setWithState(false);
    }
  }, [screenSize.dynamicWidth]);

  const handleRouter = (name: string) => {
    if (name === 'about') {
      setState(true);
      history.push({
        pathname: '/',
        state: 'about',
      });
    }
    if (name === 'contact') {
      setState(true);
      history.push({
        pathname: '/',
        state: 'contact',
      });
    }
  };
  useEffect(() => {
    if (window.location.pathname === '/') {
      setState(true);
      history.push({
        pathname: '/',
        state: false,
      });
    }
  }, [history]);
  return (
    <>
      {withState ? (
        <div className="menu-container">
          <div
            className={
              state ? 'center-menu-items-wrapper' : 'menu-items-wrapper'
            }
          >
            <a href="/">
              <img src={logo} className="logo" />
            </a>
            <div className="menu-items">
              <div>
                {window.location.pathname === '/' ? (
                  <Link
                    activeClass="active"
                    to="section2"
                    spy
                    smooth
                    offset={-42.5}
                    duration={1250}
                    className="menu-item"
                  >
                    İletişim
                  </Link>
                ) : (
                  <a
                    className="menu-item"
                    onClick={() => handleRouter('contact')}
                  >
                    İletişim
                  </a>
                )}
              </div>
              <div>
                <a className="menu-item" href="/podcastler">
                  Podcastler
                </a>
              </div>
              <div>
                <a className="menu-item" href="/booklist">
                  Kitaplar Listesi
                </a>
              </div>
              <div>
                <a className="menu-item" href="/authors">
                  Yazarlarımız
                </a>
              </div>
              <div>
                {window.location.pathname === '/' ? (
                  <Link
                    activeClass="active"
                    to="section3"
                    spy
                    smooth
                    offset={-42.5}
                    duration={1250}
                    className="menu-item"
                  >
                    Biz Kimiz?
                  </Link>
                ) : (
                  <a
                    className="menu-item"
                    onClick={() => handleRouter('about')}
                    id="gopage"
                  >
                    Biz Kimiz?
                  </a>
                )}
              </div>
            </div>
          </div>
        </div>
      ) : (
        <HamburgerMenu />
      )}
    </>
  );
};

export default Navbar;
