import React, { FunctionComponent, useState } from 'react';
import { Modal, Pagination } from 'semantic-ui-react';

import nullAuthor from '../../assets/images/nullauthor.png';
import AboutAuthor from '../../pages/AboutAuthor';
interface Props {
  data: any;
}
const Books: FunctionComponent<Props> = props => {
  const { data } = props;
  const [open, setOpen] = React.useState(false);

  const pageCount = Math.ceil(data?.length / 15);
  const [currentPage, setCurrentPage] = useState<any>(1);

  const indexOfLastPost = currentPage * 15;
  const indexOfFirstPOST = indexOfLastPost - 15;
  const pureData = data?.filter((i: { hidden: any }) => !i.hidden);
  const currentPost = pureData?.slice(indexOfFirstPOST, indexOfLastPost);

  const [authordata, setAuthorData] = useState();

  return (
    <>
      <div className="author-container">
        <div className="author-wrapper">
          {currentPost?.map((item: any, index: number) => {
            return (
              <div key={index}>
                <div className="authors-list">
                  <div className="authors-list-wrapper">
                    {item?.coverPhoto?.images == undefined ? (
                      <div className="author-undefined-container">
                        <img
                          alt="logo"
                          src={nullAuthor}
                          className="authors-img"
                          // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
                          onClick={() => {
                            setOpen(true), setAuthorData(item.name);
                          }}
                        />
                        <div className="author-undefined-text">{item.name}</div>
                      </div>
                    ) : (
                      <img
                        src={
                          item?.coverPhoto?.images?.edges[0]?.node?.file?.url ||
                          ''
                        }
                        alt="logo"
                        className="authors-img"
                        // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
                        onClick={() => {
                          setOpen(true), setAuthorData(item.name);
                        }}
                      />
                    )}

                    <div className="authors-info">
                      <div
                        className="authors-info author"
                        // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
                        onClick={() => setOpen(true)}
                      ></div>
                      <div className="authors-info authorname">{item.name}</div>
                    </div>
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      </div>
      {open ? (
        <div className="author-books-modal">
          <Modal
            // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
            onOpen={() => setOpen(!open)}
            open={open}
            scrolling
            className="modal-author"
          >
            <AboutAuthor pushdata={authordata} setOpen={setOpen} />
          </Modal>
        </div>
      ) : null}
      <div className="pagenation-container">
        <div className="pagenation-wrapper">
          <Pagination
            ellipsisItem={null}
            firstItem={null}
            lastItem={null}
            defaultActivePage={1}
            totalPages={pageCount}
            // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
            onPageChange={(event, allBooksArray) =>
              setCurrentPage(allBooksArray.activePage)
            }
          />
        </div>
      </div>
    </>
  );
};

export default Books;
