import { NextPage } from 'next';
import React from 'react';
import AuthorList from '../AuthorList';

const Activity: NextPage = () => {
  return (
    <>
      <AuthorList />
    </>
  );
};

export default Activity;
