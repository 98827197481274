import React, { FunctionComponent } from 'react';
import '../assets/css/footer.css';
import logo from '../assets/logo/logo.svg';
import facebook from '../assets/logo/facebook.svg';
import twitter from '../assets/logo/twitter.svg';
import instagram from '../assets/logo/instagram.svg';
import youtube from '../assets/logo/youtube.svg';

import { Link } from 'react-scroll';
import { animateScroll as scroll } from 'react-scroll';
import { useHistory } from 'react-router-dom';

const FooterComponent: FunctionComponent = () => {
  const scrollToTop = (): void => {
    scroll.scrollToTop();
  };
  const history = useHistory();
  const handleRouter = (name: string) => {
    if (name === 'about') {
      history.push({
        pathname: '/',
        state: 'about',
      });
    }
    if (name === 'contact') {
      history.push({
        pathname: '/',
        state: 'contact',
      });
    }
  };
  return (
    <div className="footer-container">
      <div className="footer-logo">
        <img src={logo} className="footer-logo" />
      </div>
      <div className="footer-text">
        Facebook, Twitter, Youtube ve Instagram sayfalarımıza göz atarak neler
        yaptığımız hakkında daha fazla bilgi edinebilirsiniz.
      </div>
      <div className="footer-text">
        Telif hakkı 2021 © KitapDinler Tüm hakları saklıdır
      </div>
      <a className="footer-text-secret" href="/privacy-policy">
        Gizlilik Politikası
      </a>
      <div className="footer-text">
        KitapDinler Outreach to the Least Reached sivil toplum kuruluşunun
        hizmetidir.
      </div>
      <div className="footer-menu">
        <div onClick={scrollToTop}>
          <a href="/">Anasayfa</a>
        </div>
        {window.location.pathname === '/' ? (
          <Link
            activeClass="active"
            to="section3"
            spy
            smooth
            offset={-42.5}
            duration={1250}
          >
            <div>Biz kimiz?</div>
          </Link>
        ) : (
          <a onClick={() => handleRouter('about')} id="gopage">
            Biz Kimiz?
          </a>
        )}

        <a href="/kitaplarimiz">
          <div>Kitaplar</div>
        </a>
        <a href="/podcastler">
          <div>Podcastler</div>
        </a>
        {window.location.pathname === '/' ? (
          <Link
            activeClass="active"
            to="section2"
            spy
            smooth
            offset={-42.5}
            duration={1250}
          >
            <div>İletişim</div>
          </Link>
        ) : (
          <a onClick={() => handleRouter('contact')} id="gopage">
            İletişim
          </a>
        )}
      </div>
      <div className="footer-icons">
        <div className="footer-icons">
          <a href="https://www.facebook.com/KitapDinlerApp/">
            <img src={facebook} />
          </a>
        </div>
        <div className="footer-icons">
          <a href="https://twitter.com/KitapDinler">
            <img src={twitter} />
          </a>
        </div>
        <div className="footer-icons">
          <a href="https://www.instagram.com/kitapdinler">
            <img src={instagram} />
          </a>
        </div>
        <div className="footer-icons">
          <a href="https://www.youtube.com/channel/UCHUUqQrSsEvgRrFNN8vSKcg">
            <img src={youtube} className="footer-icon-youtube" />
          </a>
        </div>
      </div>
    </div>
  );
};

export default FooterComponent;
