import React, { FunctionComponent } from 'react';
import '../assets/css/FullScreenContainer.css';

interface Props {
  data: any;
  color: string;
}
const FullScreenContainer: FunctionComponent<Props> = props => {
  const { data } = props;
  return (
    <div className="full-screen-container" id={data.section}>
      <div
        className={
          data.imgPosition === 'left'
            ? 'full-screen-card-image'
            : 'full-screen-right-card-image'
        }
      >
        <div
          className={
            data.imgPosition === 'left'
              ? 'full-screen-image'
              : 'full-screen-right-image'
          }
        >
          {data.img ? (
            <img src={data.img.default} className="full-screen-img" />
          ) : (
            <video autoPlay loop playsInline muted className="full-screen-img">
              <source src={data.video.default} type="video/mp4" />
            </video>
          )}
        </div>
        <div className="full-screen-text-wrapper">
          <div
            className={
              data.imgPosition === 'left'
                ? 'full-screen-text'
                : 'full-screen-right-text'
            }
          >
            <h1 className="full-screen-title">{data.name}</h1>
            <div className="full-screen-subtitle">{data.subTitle}</div>
            <div className="full-screen-description">{data.description}</div>
            <div className="full-screen-description">{data.subTitle2}</div>
            <div className="full-screen-description">{data.description2}</div>
            <div className="full-screen-description">{data.description3}</div>
            <img
              src={data.vector.default}
              className={
                data.imgPosition === 'left'
                  ? 'full-screen-vector'
                  : 'full-screen-right-vector'
              }
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default FullScreenContainer;
