import React, { FunctionComponent, useEffect, useMemo, useState } from 'react';

import arrow from '../assets/images/arrow.png';
import { STORE_OR_NETWORK, useQuery } from 'relay-hooks';
import { Dimmer, Loader, Segment } from 'semantic-ui-react';

import Books from '../common/booklist/Books';
import BOOKS_QUERY, {
  allBooksQuery,
} from '../__generated__/allBooksQuery.graphql';

interface Props {
  pushdata: any;
  setOpen: any;
}

const AboutAuthor: FunctionComponent<Props> = props => {
  const { pushdata, setOpen } = props;
  const [loading, setLoading] = useState<boolean>(false);
  const [loadingtext, setLoadingtext] = useState<boolean>();

  const allBooks = useQuery<allBooksQuery>(
    BOOKS_QUERY,
    { isShown: true },
    { fetchPolicy: STORE_OR_NETWORK }
  );

  const allBooksArray =
    allBooks &&
    allBooks?.data?.allBooks?.edges.map((item: any) => {
      if (item.node.isShown === false) {
        return item.node;
      } else null;
    });

  useEffect(() => {
    if (allBooks.error) {
      setLoading(true);
      setLoadingtext(true);
    } else {
      setLoading(false);
      setLoadingtext(false);

      if (
        allBooks?.data?.allBooks &&
        allBooks?.data?.allBooks &&
        allBooks?.data?.allBooks?.edges &&
        allBooks?.data?.allBooks?.edges?.length > 0
      ) {
        setLoading(false);
      } else {
        setLoading(true);
      }
    }
  }, [allBooks]);

  const AuthorMemo = useMemo(() => {
    const renderList = allBooksArray?.filter(filter => {
      return filter?.authors?.edges[0].node.name
        .toLowerCase()
        .includes(pushdata.toLowerCase());
    });

    return renderList;
  }, [allBooksArray, pushdata]);

  return (
    <div>
      {loading ? (
        <div>
          <Segment
            className="bg"
            style={{
              background: 'rgba(249, 249, 249, 0.9) !important',
              height: '60vh',
            }}
          >
            <Dimmer className="Dimmer" active>
              <div className="loading-component">
                <Loader className="loading" size="massive" />
              </div>
              {loadingtext ? (
                <div className="loading-text">Bu Sayfa Bakıma alınmıştır</div>
              ) : (
                <div className="loading-text">Yükleniyor</div>
              )}
            </Dimmer>
          </Segment>
        </div>
      ) : (
        <div
          className="bg-author"
          style={{
            background: 'rgba(249, 249, 249, 0.9) !important',
          }}
        >
          <img
            src={arrow}
            alt="logo"
            className="author-modals-backicon"
            // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
            onClick={() => setOpen(false)}
          />
          <div className="booklist author">
            <div className="booklist-filter-container">
              <div className="booklist-filter-wrapper">
                <h1 className="booklist-title author">{pushdata} Kitapları</h1>
              </div>
            </div>
          </div>
          <Books data={AuthorMemo} authors={true} />
        </div>
      )}
    </div>
  );
};
export default AboutAuthor;
