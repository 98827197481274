import React, { ReactElement, useState } from 'react';
import bookData from '../assets/data/books-pagevideo.data';
import fullScreenData from '../assets/data/bookScreenData';
import FullScreenContainer from '../common/FullScreenContainer';
import FooterComponent from '../common/Footer';
import bannerData from '../assets/data/banner-data';
import blackBannerData from '../assets/data/blackBannerData';

import BannerComponent from '../common/Banner';
import BlackBanner from '../common/BlackBanner';
import MainScreenVideo from '../common/MainScreenVideo';

const BooksPage = (): ReactElement => {
  const [height, setHeight] = useState(false);
  window.onscroll = function(): void {
    if (window.scrollY > 500) {
      setHeight(true);
    } else {
      setHeight(false);
    }
  };
  return (
    <div className="App">
      <title>Kitap Dinler Kitaplarımız</title>
      <meta
        property="og:description"
        data-rh="true"
        content="Kitap Dinler,Sesli Kitap, Kitap Dinle, Sesli Kitap İndir ,Dinle,"
      />
      <MainScreenVideo data={bookData[0]} />
      <BlackBanner data={blackBannerData[0]} />
      <div
        style={{
          background:
            'linear-gradient( 180deg, #000208 8.65%, #6d4753 24.37%, #905a81 41.39%, #502d80 58.65%, #1c1b60 75.83%, #0b1552 92.5%)',
        }}
      >
        {fullScreenData.map((item, index) => (
          <FullScreenContainer
            data={fullScreenData[index]}
            key={index}
            color="white"
          />
        ))}
      </div>
      <BannerComponent data={bannerData[0]} />
      <FooterComponent />
    </div>
  );
};

export default BooksPage;
