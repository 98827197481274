import React, { FunctionComponent } from 'react';
import '../assets/css/BlackBanner.css';
import '../assets/css/FullScreenContainer.css';
import { Link } from 'react-scroll';

interface Props {
  data: any;
}
const BlackBanner: FunctionComponent<Props> = props => {
  const { data } = props;
  return (
    <div
      className="black-banner-container"
      id={data.section}
      style={{ backgroundColor: 'black' }}
    >
      <div className="black-banner-wrapper">
        <div className="black-banner-text-wrapper">
          <div className="banner-title">{data.name}</div>
          <div className="black-banner-subtitle">{data.subTitle}</div>
          <div className="black-banner-subtitle2">{data.subTitle2}</div>
        </div>
        <Link activeClass="active" to="section1" spy smooth duration={1250}>
          <button className="black-banner-button">{data.buttonText2}</button>
        </Link>
      </div>
    </div>
  );
};

export default BlackBanner;
