import React, { FunctionComponent, useEffect, useState } from 'react';
import '../assets/css/mainScreen.css';
import { Link } from 'react-scroll';

import son from '../assets/images/soundon.png';
import soff from '../assets/images/soundoff.png';
interface Props {
  data: any;
}
const MainScreenVideo: FunctionComponent<Props> = props => {
  const { data } = props;
  const [withState, setWithState] = useState(false);
  const pageWith = window.innerWidth;

  useEffect(() => {
    if (window.innerWidth > 1024) {
      setWithState(true);
    } else {
      setWithState(false);
    }
  }, [pageWith, withState]);
  return (
    <>
      <div className="slider-container-img-forvideo">
        {data.diffPage === true ? (
          <>
            <div className="mainvideo-container">
              <div className="mainvideo-wrapper">
                <div className="title-container-head">
                  <h1 className="title">{data.title}</h1>
                  <p className="main-subtitle center">{data.subTitle}</p>
                </div>
                <div className="video-container">
                  <div className="video-wrapper">
                    <iframe
                      className="video"
                      src="https://www.youtube.com/embed/QAzsAr56oIU"
                      title="YouTube video player"
                      frameBorder={0}
                      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                      allowFullScreen
                      width={650}
                      height={400}
                    />
                  </div>
                  <div className="vide-text-container">
                    <div className="main-subtitle2">{data.subTitle2}</div>
                    <a
                      href="https://apps.apple.com/gb/app/kitapdinler/id1559060856"
                      target="blank"
                      className="download-button"
                    >
                      <button
                        className="download-button"
                        style={
                          data.backgroundColor
                            ? {
                                backgroundColor: `rgba(${data.backgroundColor})`,
                              }
                            : {
                                backgroundColor: 'transparent',
                                border: '1.4486px solid #FFFFFF',
                                color: '#ffffff',
                              }
                        }
                      >
                        <img src={data.appleLogo.default} className="icon" />
                        <h1 className="download-button-text">
                          {data.buttonText}
                        </h1>
                      </button>
                    </a>
                    <a
                      href="https://play.google.com/store/apps/details?id=com.kitapdinler.kitapdinler&gl=TR"
                      target="blank"
                      className="download-button"
                    >
                      <button
                        className="download-button"
                        style={
                          data.backgroundColor
                            ? {
                                backgroundColor: `rgba(${data.backgroundColor})`,
                              }
                            : {
                                backgroundColor: 'transparent',
                                border: '1.4486px solid #FFFFFF',
                                color: '#ffffff',
                              }
                        }
                      >
                        <img src={data.androidLogo.default} className="icon" />
                        <h1 className="download-button-text">
                          {data.buttonText2}
                        </h1>
                      </button>
                    </a>
                    <div className="text1">{data.text1}</div>
                    <div className="text2">{data.text2}</div>
                    <Link
                      activeClass="active"
                      to="section1"
                      spy
                      smooth
                      offset={-42.5}
                      duration={1250}
                      className="button-container"
                    >
                      <button className="button"> {data.buttonText3}</button>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </>
        ) : null}
      </div>
    </>
  );
};

export default MainScreenVideo;
