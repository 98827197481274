const vector1 = require('../vector/Category.svg');
const vector2 = require('../vector/Union.svg');
const vector3 = require('../vector/Vector1.svg');
const vector4 = require('../vector/Vector2.svg');
const vector5 = require('../vector/Vector3.svg');

const image1 = require('../images/podcastImg1.svg');
const image2 = require('../images/podcastImg2.svg');
const image3 = require('../images/podcastImg3.svg');
const image4 = require('../images/podcastImg4.svg');
const image5 = require('../images/podcastImg5.svg');

export default [
  {
    name: 'EN ÇOK DİNLENENLER',
    // subTitle: 'Uygulama ekran görüntüleriyle kullanım tanıtımı bölümü.',
    description: 'Podcastler bize hayatın bir parçasını sunar.',
    description2:
      'Belki de orada sizinde yüreğinize seslenen sözcükler bulursunuz.',
    description3:
      'Çünkü Podcastler bizi eğitiyor ve bize ilham veriyorlar. En çok dinlenen Podcastlerimize hemen gözatabilirsiniz.',
    img: image1,
    imgPosition: 'left',
    section: 'section1',
    vector: vector5,
  },
  {
    name: 'YENİ ÇIKANLAR',
    description: 'En yeni çıkan Podcastlerimizi görmek ister misiniz?',
    description2:
      'En yeni ve en iyi Podcast seçenekleriyle Kitap Dinler sizlerle.',
    description3: 'Hemen şimdi bir podcast`e başlamaya ne dersin ?',
    img: image2,
    imgPosition: 'right',
    vector: vector1,
  },
  {
    name: 'PROGRAMLAR',
    description:
      'Podcast bölümlerinde her başlık altında birden fazla programlar bulabilirsiniz.',
    description2:
      'Sizin için en iyi programı seçip Podcast dinlemenin keyfini çıkarabilirsiniz.',
    description3:
      'Şimdi hemen uygulamaya göz atarak size uygun olan Podcast programını dinlemeye başlayabilirsiniz.',
    img: image3,
    imgPosition: 'left',
    vector: vector4,
  },
  {
    name: 'BÖLÜMLER',
    description:
      'Podcast programlarının sürekli yenilenen bölümlerine göz atabilirsiniz.',
    description2: 'İstediğiniz bölümden başlayarak dinleyebilirsiniz. ',
    description3:
      'Ayrıca çevrimdışı olarak da dilediğiniz zaman ve dilediğiniz yerde dinlemeye devam edebilirsiniz.',
    img: image4,
    imgPosition: 'right',
    vector: vector3,
  },
  {
    name: 'ARA',
    description: 'Sizin için en uygun Podcast seçeneğini bulmak istermisiniz? ',
    description2: 'Hemen şimdi birgöz atıp dinlemeye başlayabilirsiniz.',
    img: image5,
    imgPosition: 'left',
    vector: vector2,
  },
];
