const img1 = require('../images/bookPhoneImg.svg');
const androidLogo = require('../logo/androidLogo.svg');
const appleLogo = require('../logo/appleLogo.svg');
const video = require('../videos/bookVideo.mp4');
export default [
  {
    title: 'KİTAPLARIMIZ',
    text1: 'Uygulamamızı Ücretsiz Edinmek İster misiniz?',
    text2:
      'Tüm Tablet ve Telefon Modellerine Uygun Olarak Geliştirilmiş Uygulamamızı Hemen Şimdi İndirip Podcastleri Dinlemeye Başlayabilirsiniz.',
    subTitle: 'Dinle. Düşün. Değiş',
    subTitle2: 'Şimdi İndir!',
    video: video,
    buttonText: 'Apple Store',
    buttonText2: 'Google Play',
    buttonText3: 'Bilgi Al',
    diffPage: true,
    androidLogo: androidLogo,
    appleLogo: appleLogo,
    backgroundColor: '255, 255, 255, 0.8',
  },
  {
    title: 'Yazarlarımız',
    text1: 'Uygulamamızı Ücretsiz Edinmek İster misiniz?',
    text2:
      'Tüm Tablet ve Telefon Modellerine Uygun Olarak Geliştirilmiş Uygulamamızı Hemen Şimdi İndirip Podcastleri Dinlemeye Başlayabilirsiniz.',
    subTitle: 'Dinle. Düşün. Değiş',
    subTitle2: 'Şimdi İndir!',
    video: video,
    buttonText: 'Apple Store',
    buttonText2: 'Google Play',
    buttonText3: 'Bilgi Al',
    diffPage: true,
    androidLogo: androidLogo,
    appleLogo: appleLogo,
    backgroundColor: '255, 255, 255, 0.8',
  },
];
