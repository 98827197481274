import React, {
  FunctionComponent,
  useEffect,
  useState,
  ReactElement,
} from 'react';
import '../assets/css/home.styles.css';
import firstData from '../assets/data/first-page-data';
import fullScreenData from '../assets/data/fullScreenData';
import whoAreWE from '../assets/data/whoAreWe.data';
import bannerData from '../assets/data/banner-data';
import MainScreen from '../common/MainScreen';
import FullScreenContainer from '../common/FullScreenContainer';
import BannerComponent from '../common/Banner';
import FormComponent from '../common/form/form';
import FooterComponent from '../common/Footer';
import ScrollButtonComponent from '../common/ScrolleButton';
import WhoAreWeComponent from '../common/whoAreWe';
import { useLocation } from 'react-router-dom';
import { animateScroll as scroll } from 'react-scroll';

interface Props {
  element: Element | null;
}
const Home: FunctionComponent<Props> = (props): ReactElement => {
  const [height, setHeight] = useState(false);
  const location = useLocation();
  const element = document.querySelector('#section3');
  window.onscroll = function(): void {
    if (window.scrollY > 500) {
      setHeight(true);
    } else {
      setHeight(false);
    }
  };

  useEffect(() => {
    if (location?.state === 'about') {
      const el = document.querySelector('#section3');
      if (el) {
        return el.scrollIntoView({
          behavior: 'smooth',
          block: 'end',
        });
      }
    }
    if (location?.state === 'contact') {
      const el = document.querySelector('#section2');
      if (el) {
        return el.scrollIntoView({
          behavior: 'smooth',
          block: 'end',
        });
      }
    }
    return scroll.scrollToTop();
  }, [location, element]);
  return (
    <div className="home-container">
      <title>Kitap Dinler</title>
      <meta
        property="og:description"
        data-rh="true"
        content="Kitap Dinler,Sesli Kitap, Kitap Dinle, Sesli Kitap İndir ,Dinle,"
      />
      <link href="/" rel="stylesheet"></link>
      <MainScreen data={firstData[0]} />
      <ScrollButtonComponent height={height} />
      <div
        style={{
          background:
            'linear-gradient( 180deg, #000208 8.65%, #6d4753 24.37%, #905a81 41.39%, #502d80 58.65%, #1c1b60 75.83%, #0b1552 92.5%)',
        }}
      >
        {fullScreenData.map((item, index) => (
          <FullScreenContainer
            data={fullScreenData[index]}
            key={index}
            color="white"
          />
        ))}
      </div>
      <WhoAreWeComponent data={whoAreWE[0]} color="black" />
      <BannerComponent data={bannerData[0]} />
      <FormComponent />
      <FooterComponent />
    </div>
  );
};

export default Home;
