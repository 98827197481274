import React, { FunctionComponent, ReactElement } from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import AuthorList from '../pages/yazarlarimiz/index';
import BookList from '../pages/BookList';
import Books from '../pages/BooksPage';
import Home from '../pages/Home';
import Podcasts from '../pages/Podcasts';
import PrivacyPolicy from '../pages/PrivacyPolicy';
import About from '../pages/PrivacyPolicy';

type Props = {
  children: ReactElement;
};

const RoutingProvider: FunctionComponent<Props> = (props): ReactElement => {
  const { children } = props;
  const element = document.querySelector('#section3');
  return (
    <Router>
      <div>
        {children}
        <Switch>
          <Route exact path="/" Component={Home}>
            <Home element={element} />
          </Route>
          <Route path="/authors">
            <AuthorList />
          </Route>
          <Route path="/privacy-policy">
            <PrivacyPolicy />
          </Route>
          <Route path="/about">
            <About />
          </Route>
          <Route path="/booklist">
            <BookList />
          </Route>
          <Route path="/kitaplarimiz">
            <Books />
          </Route>
          <Route path="/podcastler">
            <Podcasts />
          </Route>
        </Switch>
      </div>
    </Router>
  );
};

export default RoutingProvider;
