import React, { FunctionComponent, useRef, useState } from 'react';
import '../assets/css/scrollButton.css';
import scrollButton from '../assets/logo/scrollButton.svg';
import { animateScroll as scroll } from 'react-scroll';

interface Props {
  height: boolean;
}
const ScrollButtonComponent: FunctionComponent<Props> = props => {
  const { height } = props;
  const [state] = useState(false);
  const ref = useRef(null);
  const scrollToTop = (): void => {
    scroll.scrollToTop();
  };
  return (
    <div
      className={
        height === true
          ? 'scroll-button-container'
          : 'scroll-button-hidden-container'
      }
    >
      <div
        className={state === false ? 'scroll-button-wrapper' : 'is-nav-open'}
        ref={ref}
      >
        <button className="scroll-button" onClick={scrollToTop}>
          <img src={scrollButton} />
        </button>
      </div>
    </div>
  );
};

export default ScrollButtonComponent;
