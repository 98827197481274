const vector1 = require('../vector/Category.svg');
const vector2 = require('../vector/Union.svg');
const vector3 = require('../vector/Vector1.svg');
const vector4 = require('../vector/Vector2.svg');
const vector5 = require('../vector/Vector3.svg');

const vector6 = require('../vector/Vector4.svg');
const video1 = require('../videos/1.mp4');
const video2 = require('../videos/2.mp4');
const video3 = require('../videos/3.mp4');
const video4 = require('../videos/4.mp4');
const video5 = require('../videos/5.mp4');
const video6 = require('../videos/6.mp4');
export default [
  {
    name: 'Sadece dinleyerek kitap okumak ister misiniz ? ',
    // subTitle: 'Uygulama ekran görüntüleriyle kullanım tanıtımı bölümü.',
    description:
      '“Kitap Dinler”de gözlerinizi yormadan kitaplar arasında dolaşın, dinleyin,öğrenin! Onlarca ve yüzlerce kitap şimdi sizin kulaklarınız aracılığı ile zihninize ve yüreğinize ulaşabiliyor.',
    video: video1,
    imgPosition: 'left',
    section: 'section1',
    vector: vector5,
  },
  {
    name: 'Ne okuyacağınıza karar veremiyor musunuz?',
    description:
      '“Kitap Dinler”de birçok farklı konudan kendi zevkinize göre bir kitap seçip hayal gücünüzün dünyasını keşfedin.',
    video: video2,
    imgPosition: 'right',

    vector: vector1,
  },
  {
    name: 'Unutkan mısınız?',
    description:
      '‘’Kitap Dinler’’ daima sizinle beraber olacak. İnternet bağlantınızın olup olmamasının artık önemi yok. Bir kitabı dinlerken istediğiniz zaman durdurup düşüncelerinizi not alabilirsiniz ve istediğiniz zaman notlarınızı açıp okuyabilirsiniz!',
    video: video3,
    imgPosition: 'left',

    vector: vector4,
  },
  {
    name: 'Ağır sırt çantası, şiddetli bel ağrısı.',
    description:
      'Siz de bulduğunuz her fırsatta kitap ya da kitaplar okumayı sevenlerdenseniz ağır çantalarınız sebebiyle muhtemelen bel ağrısıyla yaşamayı öğrenmişsinizdir. “Kitap Dinler” ile aynı anda pek çok kitaptan araştırma yapabilir, dinleyebilir ve öğrenebilirsiniz. Bunları yaparken sırt çantanızda kitaplar taşımanıza gerek kalmayacak.',
    video: video4,
    imgPosition: 'right',
    vector: vector3,
  },
  {
    name: 'Kitaplar sahip olduğumuz en büyük hazinelerden biridir. ',
    description:
      'Kitaplar sizin dünya anlayışınızı daha fazla geliştirir. Bırakın ‘’Kitap Dinler’’ farklı deneyimler ve farklı düşünceler ile sizin fikir dünyanızı zenginleştirsin! Tüm kitaplara bir tıklamayla ücretsiz ulaşabileceksiniz',
    video: video5,
    imgPosition: 'left',
    vector: vector2,
  },
  {
    name: 'Paylaşmak güzeldir.',
    description:
      'Bir başkasına kitap hediye etmek her zaman olduğu gibi bugün de çok değerli ve ince bir davranıştır. Ama artık bunu yapmak için kargo şirketine gidip gönderi adreslerini yazmanıza gerek yok. Beğendiğiniz bir kitabı dilediğiniz bir arkadaşınıza mesaj yolu ile gönderebilir, tavsiye edebilirsiniz.',
    video: video6,
    imgPosition: 'right',
    vector: vector6,
  },
];
