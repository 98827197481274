import React, { ReactElement } from 'react';
import '../assets/css/privacypolicy.css';

const PrivacyPolicy = (): ReactElement => {
  return (
    <div className="privacypolicy-container">
      <title>Kitap Dinler Privacypolicy</title>
      <meta
        property="og:description"
        data-rh="true"
        content="Kitap Dinler,Sesli Kitap, Kitap Dinle, Sesli Kitap İndir ,Dinle,"
      />
      <link href="/privacy-policy" rel="stylesheet"></link>
      <div className="privacypolicy-wrapper">
        <p>Privacy Policy</p>
        <p>
          Outreach To The Least Reached built the KitapDinler app as a Free app.
          This SERVICE is provided by Outreach To The Least Reached at no cost
          and is intended for use as is.
        </p>
        <p>
          This page is used to inform visitors regarding our policies with the
          collection, use, and disclosure of Personal Information if anyone
          decided to use our Service. If you choose to use our Service, then you
          agree to the collection and use of information in relation to this
          policy. The Personal Information that we collect is used for providing
          and improving the Service. We will not use or share your information
          with anyone except as described in this Privacy Policy. The terms used
          in this Privacy Policy have the same meanings as in our Terms and
          Conditions, which is accessible at KitapDinler unless otherwise
          defined in this Privacy Policy.
        </p>
        <p>Information Collection and Use</p>
        <p>
          For a better experience, while using our Service, we may require you
          to provide us with certain personally identifiable information,
          including but not limited to None. The information that we request
          will be retained by us and used as described in this privacy policy.
          The app does use third party services that may collect information
          used to identify you. Link to privacy policy of third party service
          providers used by the app
        </p>
        <p>• Google Play Services</p>
        <p>Log Data</p>
        <p>
          We want to inform you that whenever you use our Service, in a case of
          an error in the app we collect data and information (through third
          party products) on your phone called Log Data. This Log Data may
          include information such as your device Internet Protocol (“IP”)
          address, device name, operating system version, the configuration of
          the app when utilizing our Service, the time and date of your use of
          the Service, and other statistics. Cookies
        </p>
        <p>
          Cookies are files with a small amount of data that are commonly used
          as anonymous unique identifiers. These are sent to your browser from
          the websites that you visit and are stored on your device`s internal
          memory. This Service does not use these “cookies” explicitly. However,
          the app may use third party code and libraries that use “cookies” to
          collect information and improve their services. You have the option to
          either accept or refuse these cookies and know when a cookie is being
          sent to your device. If you choose to refuse our cookies, you may not
          be able to use some portions of this Service. Service Providers
        </p>
        <p>
          We may employ third-party companies and individuals due to the
          following reasons:
        </p>
        <p>• To facilitate our Service;</p>
        <p>• To provide the Service on our behalf;</p>
        <p>• To perform Service-related services; or</p>
        <p>• To assist us in analyzing how our Service is used.</p>
        <p>
          We want to inform users of this Service that these third parties have
          access to your Personal Information. The reason is to perform the
          tasks assigned to them on our behalf. However, they are obligated not
          to disclose or use the information for any other purpose. Security
        </p>
        <p>
          We value your trust in providing us your Personal Information, thus we
          are striving to use commercially acceptable means of protecting it.
          But remember that no method of transmission over the internet, or
          method of electronic storage is 100% secure and reliable, and we
          cannot guarantee its absolute security. Links to Other Sites
        </p>
        <p>
          This Service may contain links to other sites. If you click on a
          third-party link, you will be directed to that site. Note that these
          external sites are not operated by us. Therefore, we strongly advise
          you to review the Privacy Policy of these websites. We have no control
          over and assume no responsibility for the content, privacy policies,
          or practices of any third-party sites or services. Children’s Privacy
        </p>
        <p>
          These Services do not address anyone under the age of 13. We do not
          knowingly collect personally identifiable information from children
          under 13 years of age. In the case we discover that a child under 13
          has provided us with personal information, we immediately delete this
          from our servers. If you are a parent or guardian and you are aware
          that your child has provided us with personal information, please
          contact us so that we will be able to do necessary actions. Changes to
          This Privacy Policy
        </p>
        <p>
          We may update our Privacy Policy from time to time. Thus, you are
          advised to review this page periodically for any changes. We will
          notify you of any changes by posting the new Privacy Policy on this
          page. This policy is effective as of 2021-03-19
        </p>
        <p>
          Contact Us If you have any questions or suggestions about our Privacy
          Policy, do not hesitate to contact us at admin@kitapdinler.com. This
          privacy policy page was created at privacypolicytemplate.net and
          modified/generated by App Privacy Policy Generator
        </p>
      </div>

      <div className="privacypolicy-wrapper">
        <p>Gizlilik Politikası</p>
        <p>
          Outreach To The Least Reached KitapDinler app`i bedava bir app olarak
          kurmuştur. Bu hizmet Outreach to the Least Reached şirketinden
          ücretsiz olarak sağlanıyor ve bu şekilde kullanılmak isteniyor.{' '}
        </p>
        <p>
          Bu sayfa, herhangi birinin Hizmetimizi kullanmaya karar vermesi
          durumunda Kişisel Bilgilerin toplanması, kullanılması ve ifşa edilmesi
          ile ilgili politikalarımız hakkında ziyaretçileri bilgilendirmek için
          kullanılır. Hizmetimizi kullanmayı seçerseniz, bu politikayla ilgili
          bilgilerin toplanmasını ve kullanılmasını kabul etmiş olursunuz.
          Topladığımız Kişisel Bilgiler, Hizmeti sağlamak ve geliştirmek için
          kullanılır. Bilgilerinizi bu Gizlilik Politikasında açıklanan durumlar
          dışında hiç kimseyle kullanmayacağız veya paylaşmayacağız.
        </p>
        <p>
          Bu Gizlilik Politikasında kullanılan terimler, bu Gizlilik
          Politikasında aksi belirtilmedikçe KitapDinler`de erişilebilen Hüküm
          ve Koşullarımızdaki anlamlarla aynıdır.
        </p>
        <p>Bilgi Toplama ve Kullanma</p>
        <p>
          Daha iyi bir deneyim için, Hizmetimizi kullanırken, Hiçbiri dahil
          ancak bunlarla sınırlı olmamak üzere bize bazı kişisel olarak
          tanımlanabilir bilgiler sağlamanızı isteyebiliriz. Talep ettiğimiz
          bilgiler tarafımızca saklanacak ve bu gizlilik politikasında
          açıklandığı gibi kullanılacaktır.
        </p>
        <p>
          Daha iyi bir deneyim için, Hizmetimizi kullanırken, Hiçbiri dahil
          ancak bunlarla sınırlı olmamak üzere bize bazı kişisel olarak
          tanımlanabilir bilgiler sağlamanızı isteyebiliriz. Talep ettiğimiz
          bilgiler tarafımızca saklanacak ve bu gizlilik politikasında
          açıklandığı gibi kullanılacaktır. Uygulama, sizi tanımlamak için
          kullanılan bilgileri toplayabilecek üçüncü taraf hizmetlerini
          kullanıyor. Uygulama tarafından kullanılan üçüncü taraf hizmet
          sağlayıcıların gizlilik politikasına bağlantı HYPERLINK
          `https://www.google.com/policies/privacy/` \n _blank HYPERLINK
          `https://www.google.com/policies/privacy/` \n _blank
          <a href="https://www.google.com/policies/privacy/" target="blank">
            Google Play Services
          </a>
        </p>
        <p>Günlük Verileri</p>
        <p>
          Hizmetimizi her kullandığınızda, uygulamada bir hata olması durumunda,
          telefonunuzda Günlük Verileri adı verilen veri ve bilgileri (üçüncü
          taraf ürünler aracılığıyla) topladığımızı size bildirmek istiyoruz. Bu
          Günlük Verileri, cihazınızın İnternet Protokolü (`IP`) adresi, cihaz
          adı, işletim sistemi sürümü, Hizmetimizi kullanırken uygulamanın
          yapılandırması, Hizmeti kullanımınızın saati ve tarihi ve diğer
          istatistikler gibi bilgileri içerebilir.{' '}
        </p>
        <p>Çerezler</p>
        <p>
          Çerezler, genellikle anonim benzersiz tanımlayıcılar olarak kullanılan
          az miktarda veri içeren dosyalardır. Bunlar, ziyaret ettiğiniz web
          sitelerinden tarayıcınıza gönderilir ve cihazınızın dahili belleğinde
          saklanır. Bu Hizmet, bu `çerezleri` açıkça kullanmaz. Ancak uygulama,
          bilgi toplamak ve hizmetlerini iyileştirmek için `çerezler` kullanan
          üçüncü taraf kod ve kitaplıkları kullanabilir. Bu çerezleri kabul etme
          veya reddetme ve cihazınıza bir çerez gönderildiğinde bunu bilme
          seçeneğiniz vardır. Çerezlerimizi reddetmeyi seçerseniz, bu Hizmetin
          bazı kısımlarını kullanamayabilirsiniz.
        </p>
        <p>Servis sağlayıcıları</p>
        <p>
          Aşağıdaki nedenlerle üçüncü taraf şirketleri ve kişileri istihdam
          edebiliriz:
        </p>
        <p>Hizmetimizi kolaylaştırmak için;</p>
        <p>Hizmeti bizim adımıza sağlamak için;</p>
        <p>Hizmetle ilgili hizmetleri gerçekleştirmek için; veya</p>
        <p>
          Hizmetimizin nasıl kullanıldığını analiz etmemize yardımcı olmak için.
        </p>
        <p>
          Bu Hizmetin kullanıcılarını, bu üçüncü tarafların Kişisel
          Bilgilerinize erişimi olduğu konusunda bilgilendirmek istiyoruz. Bunun
          nedeni, kendilerine verilen görevleri bizim adımıza yerine
          getirmektir. Ancak, bilgileri başka bir amaçla açıklamamak veya
          kullanmamakla yükümlüdürler.
        </p>
        <p>Güvenlik</p>
        <p>
          Bize Kişisel Bilgilerinizi sağlama konusundaki güveninize değer
          veriyoruz, bu nedenle bunları korumak için ticari olarak kabul
          edilebilir yöntemler kullanmaya çalışıyoruz. Ancak internet üzerinden
          hiçbir aktarım yönteminin veya elektronik depolama yönteminin %100
          güvenli ve güvenilir olmadığını ve mutlak güvenliğini garanti
          edemeyeceğimizi unutmayın.
        </p>
        <p>Diğer Sitelere Bağlantılar</p>
        <p>
          Bu servis diğer sitelere bağlantılar içerebilir. Üçüncü taraf bir
          bağlantıya tıklarsanız, o siteye yönlendirileceksiniz. Bu harici
          sitelerin bizim tarafımızdan işletilmediğini unutmayın. Bu nedenle, bu
          web sitelerinin Gizlilik Politikasını incelemenizi şiddetle tavsiye
          ederiz. Üçüncü taraf sitelerin veya hizmetlerin içeriği, gizlilik
          politikaları veya uygulamaları üzerinde hiçbir kontrolümüz yoktur ve
          sorumluluk kabul etmiyoruz.
        </p>
        <p>Çocukların Gizliliği</p>
        <p>
          Bu Hizmetler, 13 yaşın altındaki hiç kimseye hitap etmez. 13 yaşın
          altındaki çocuklardan kişisel olarak tanımlanabilecek bilgileri
          bilerek toplamıyoruz. 13 yaşın altındaki bir çocuğun bize kişisel
          bilgi verdiğini fark edersek, bunu derhal sunucularımızdan sileriz.
          Bir ebeveyn veya veli iseniz ve çocuğunuzun bize kişisel bilgiler
          verdiğini biliyorsanız, lütfen gerekli işlemleri yapabilmemiz için
          bizimle iletişime geçin.
        </p>
        <p>Bu Gizlilik Politikasındaki Değişiklikler</p>
        <p>
          Gizlilik Politikamızı zaman zaman güncelleyebiliriz. Bu nedenle,
          herhangi bir değişiklik için bu sayfayı periyodik olarak gözden
          geçirmeniz önerilir. Yeni Gizlilik Politikasını bu sayfada
          yayınlayarak herhangi bir değişikliği size bildireceğiz. Bu politika
          2021-03-19 tarihinden itibaren geçerlidir.
        </p>
        <p>Bize Ulaşın</p>
        <p>
          Gizlilik Politikamız ile ilgili herhangi bir sorunuz veya öneriniz
          varsa, admin@kitapdinler.com adresinden bizimle iletişime geçmekten
          çekinmeyin. Bu gizlilik politikası sayfası
          privacypolicytemplate.net`te oluşturulmuş ve App Privacy Policy
          Generator tarafından değiştirilmiş / oluşturulmuştur.
        </p>
      </div>
      <br />
      <br />
      <br />
    </div>
  );
};

export default PrivacyPolicy;
